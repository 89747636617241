import type { User as ParseUser } from 'parse'

export default defineNuxtPlugin(async () => {
  const Cookies = process.client
    ? (await import('js-cookie')).default
    : undefined
  const cookieParser = process.server
    ? (await import('cookie')).default
    : undefined

  const config = useRuntimeConfig()
  const { cookie } = useRequestHeaders()
  const isProd = process.env.NODE_ENV === 'production'
  const zillinksCookieOptions = {
    expires: 30,
    path: '/',
    domain: isProd ? '.zillinks.com' : 'localhost',
    secure: isProd
  }

  const user = {
    // 사용자 정보(세션 토큰)를 쿠키에 등록합니다.
    setSessionTokenCookie(user: ParseUser) {
      if (!Cookies) return
      Cookies.set(
        config.public.LOGIN_COOKIE,
        user.getSessionToken(),
        zillinksCookieOptions
      ) // Expire after 30 days, Same as `sessionLength` of API
    },
    // 쿠키에 등록된 사용자 정보(세션 토큰)을 쿠키에서 제거합니다.
    unsetSessionTokenCookie() {
      if (!Cookies) return
      Cookies.remove(config.public.LOGIN_COOKIE, zillinksCookieOptions)
    },
    // 세션 토큰을 반환합니다.
    getSessionTokenCookie() {
      let auth = ''

      // Server side
      if (process.server && cookieParser && cookie) {
        const token =
          cookieParser.parse(cookie)[
            process.env.NUXT_PUBLIC_LOGIN_COOKIE as string
          ]
        token && (auth = token)
      }
      // Client side
      if (process.client && Cookies) {
        const token = Cookies.get(config.public.LOGIN_COOKIE)
        token && (auth = token)
      }

      return auth
    },
    // Server Side Parse.Cloud 전용 세션 토큰 옵션 반환!
    get parseToken() {
      return {
        sessionToken: this.getSessionTokenCookie()
      }
    },
    // 세션토큰이 localStorage 존재하지면 만료되거나 DB에 없는 경우, 모두 삭제
    validateSessionTokenOfUser() {
      const { $Parse } = useNuxtApp()
      const user = $Parse.User.current()
      if (user) {
        $Parse.Session.current()
          .then(() => {})
          .catch(error => {
            console.log(error)

            // 로컬 스토리지 초기화, 페이지 새로고침
            window.localStorage.clear()
            window.location.reload()
            this.unsetSessionTokenCookie()
          })
      }
    },
    // 소셜 회원가입 후, 자동 로그인
    autoLoginWithSNS() {
      const { $Parse } = useNuxtApp()
      const snsAuthCookie = 'zillinks_auth'

      if (!Cookies) return Promise.resolve(false)
      if (!Cookies.get(snsAuthCookie)) {
        return Promise.resolve(false)
      }

      const [provider, id, access_token] = decodeURIComponent(
        Cookies.get(snsAuthCookie) as string
      ).split('/')

      return new Promise((resolve, reject) => {
        $Parse.User.logInWith(provider, {
          authData: {
            id,
            access_token
          }
        })
          .then(user => {
            // Register the user's session token in the cookie.
            this.setSessionTokenCookie(user)
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            Cookies.remove(snsAuthCookie, {
              domain:
                process.env.NODE_ENV === 'production'
                  ? `.${process.env.ZILLINKS_DOMAIN}`
                  : process.env.ZILLINKS_DOMAIN
            })
          })
      })
    },
    logIn(payload: { id: string; pw: string }) {
      const { $Parse } = useNuxtApp()
      return new Promise((resolve, reject) => {
        const { id, pw } = payload
        $Parse.User.logIn(id, pw)
          .then(user => {
            this.setSessionTokenCookie(user)
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logOut() {
      const { $Parse } = useNuxtApp()
      return new Promise((resolve, reject) => {
        $Parse.User.logOut()
          .then(user => {
            this.unsetSessionTokenCookie()
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }

  return {
    provide: {
      user
    }
  }
})
