export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"naver-site-verification","content":"e1b2b19bbef4c08ac6dffcff9192456357d74cd1"}],"link":[{"rel":"shortcut icon","hid":"shortcut icon","href":"/favicon.ico"},{"rel":"icon","hid":"icon","href":"/favicon.png"},{"rel":"apple-touch-icon","hid":"apple-touch-icon","href":"/favicon.png"},{"rel":"apple-touch-icon-precomposed","hid":"apple-touch-icon-precomposed","href":"/favicon.png"},{"rel":"preconnect","href":"https://api.zillinks.com"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","crossorigin":"anonymous","href":"https://cdn.jsdelivr.net"},{"rel":"preconnect","crossorigin":"anonymous","href":"https://fonts.gstatic.com"},{"rel":"stylesheet","as":"style","crossorigin":"anonymous","href":"https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable-dynamic-subset.css"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false