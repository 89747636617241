import { default as auto_45fill_45formSfVviHfmv1Meta } from "/vercel/path0/pages/auto-fill-form.vue?macro=true";
import { default as historyxcmuUgvSmlMeta } from "/vercel/path0/pages/company/[id]/history.vue?macro=true";
import { default as indexmY4DgA4cL7Meta } from "/vercel/path0/pages/company/[id]/index.vue?macro=true";
import { default as investment7c80iScXfcMeta } from "/vercel/path0/pages/company/[id]/investment.vue?macro=true";
import { default as iprIzPbcmfqMmMeta } from "/vercel/path0/pages/company/[id]/ipr.vue?macro=true";
import { default as networkgraphKpZvOaHuWUMeta } from "/vercel/path0/pages/company/[id]/networkgraph.vue?macro=true";
import { default as newszFi7Ot47ozMeta } from "/vercel/path0/pages/company/[id]/news.vue?macro=true";
import { default as portfolioL6MbxaS9RDMeta } from "/vercel/path0/pages/company/[id]/portfolio.vue?macro=true";
import { default as supportBizuVqCU306VnMeta } from "/vercel/path0/pages/company/[id]/supportBiz.vue?macro=true";
import { default as teammembersYXLYuFXamZMeta } from "/vercel/path0/pages/company/[id]/teammembers.vue?macro=true";
import { default as _91id_93JbMDDprxS4Meta } from "/vercel/path0/pages/company/[id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as mainWBumncU2ZSMeta } from "/vercel/path0/pages/main.vue?macro=true";
import { default as news_45writing4pW0cVBBxcMeta } from "/vercel/path0/pages/news-writing.vue?macro=true";
import { default as dashboarda6tZA4OddUMeta } from "/vercel/path0/pages/okceo/dashboard.vue?macro=true";
import { default as settingsbhbroM0c6IMeta } from "/vercel/path0/pages/okceo/settings.vue?macro=true";
import { default as okceo1X60MJYpbiMeta } from "/vercel/path0/pages/okceo.vue?macro=true";
import { default as analyticsdvIr0ZzimIMeta } from "/vercel/path0/pages/performance-survey/analytics.vue?macro=true";
import { default as companies7uYirmijdqMeta } from "/vercel/path0/pages/performance-survey/companies.vue?macro=true";
import { default as performancegVB0EIfm2ZMeta } from "/vercel/path0/pages/performance-survey/performance.vue?macro=true";
import { default as programsui9SajmMgcMeta } from "/vercel/path0/pages/performance-survey/programs.vue?macro=true";
import { default as performance_45surveyYuCDnLYJuPMeta } from "/vercel/path0/pages/performance-survey.vue?macro=true";
import { default as _91type_93bE8ovGuA9dMeta } from "/vercel/path0/pages/plugins/[type].vue?macro=true";
import { default as indexXwH4DxFxURMeta } from "/vercel/path0/pages/plugins/index.vue?macro=true";
import { default as chartsfrZAg5zesOMeta } from "/vercel/path0/pages/sales/charts.vue?macro=true";
import { default as goalsNZ2A82zN6uMeta } from "/vercel/path0/pages/sales/goals.vue?macro=true";
import { default as invoicesKeS6Oa0OmZMeta } from "/vercel/path0/pages/sales/invoices.vue?macro=true";
import { default as salesAwqpridKucMeta } from "/vercel/path0/pages/sales.vue?macro=true";
import { default as companiesf65Xa76TCwMeta } from "/vercel/path0/pages/search/companies.vue?macro=true";
import { default as indexDqV3Lxa5AvMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
export default [
  {
    name: auto_45fill_45formSfVviHfmv1Meta?.name ?? "auto-fill-form",
    path: auto_45fill_45formSfVviHfmv1Meta?.path ?? "/auto-fill-form",
    meta: auto_45fill_45formSfVviHfmv1Meta || {},
    alias: auto_45fill_45formSfVviHfmv1Meta?.alias || [],
    redirect: auto_45fill_45formSfVviHfmv1Meta?.redirect,
    component: () => import("/vercel/path0/pages/auto-fill-form.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JbMDDprxS4Meta?.name ?? undefined,
    path: _91id_93JbMDDprxS4Meta?.path ?? "/company/:id()",
    meta: _91id_93JbMDDprxS4Meta || {},
    alias: _91id_93JbMDDprxS4Meta?.alias || [],
    redirect: _91id_93JbMDDprxS4Meta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id].vue").then(m => m.default || m),
    children: [
  {
    name: historyxcmuUgvSmlMeta?.name ?? "company-id-history",
    path: historyxcmuUgvSmlMeta?.path ?? "history",
    meta: historyxcmuUgvSmlMeta || {},
    alias: historyxcmuUgvSmlMeta?.alias || [],
    redirect: historyxcmuUgvSmlMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/history.vue").then(m => m.default || m)
  },
  {
    name: indexmY4DgA4cL7Meta?.name ?? "company-id",
    path: indexmY4DgA4cL7Meta?.path ?? "",
    meta: indexmY4DgA4cL7Meta || {},
    alias: indexmY4DgA4cL7Meta?.alias || [],
    redirect: indexmY4DgA4cL7Meta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: investment7c80iScXfcMeta?.name ?? "company-id-investment",
    path: investment7c80iScXfcMeta?.path ?? "investment",
    meta: investment7c80iScXfcMeta || {},
    alias: investment7c80iScXfcMeta?.alias || [],
    redirect: investment7c80iScXfcMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/investment.vue").then(m => m.default || m)
  },
  {
    name: iprIzPbcmfqMmMeta?.name ?? "company-id-ipr",
    path: iprIzPbcmfqMmMeta?.path ?? "ipr",
    meta: iprIzPbcmfqMmMeta || {},
    alias: iprIzPbcmfqMmMeta?.alias || [],
    redirect: iprIzPbcmfqMmMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/ipr.vue").then(m => m.default || m)
  },
  {
    name: networkgraphKpZvOaHuWUMeta?.name ?? "company-id-networkgraph",
    path: networkgraphKpZvOaHuWUMeta?.path ?? "networkgraph",
    meta: networkgraphKpZvOaHuWUMeta || {},
    alias: networkgraphKpZvOaHuWUMeta?.alias || [],
    redirect: networkgraphKpZvOaHuWUMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/networkgraph.vue").then(m => m.default || m)
  },
  {
    name: newszFi7Ot47ozMeta?.name ?? "company-id-news",
    path: newszFi7Ot47ozMeta?.path ?? "news",
    meta: newszFi7Ot47ozMeta || {},
    alias: newszFi7Ot47ozMeta?.alias || [],
    redirect: newszFi7Ot47ozMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/news.vue").then(m => m.default || m)
  },
  {
    name: portfolioL6MbxaS9RDMeta?.name ?? "company-id-portfolio",
    path: portfolioL6MbxaS9RDMeta?.path ?? "portfolio",
    meta: portfolioL6MbxaS9RDMeta || {},
    alias: portfolioL6MbxaS9RDMeta?.alias || [],
    redirect: portfolioL6MbxaS9RDMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: supportBizuVqCU306VnMeta?.name ?? "company-id-supportBiz",
    path: supportBizuVqCU306VnMeta?.path ?? "supportBiz",
    meta: supportBizuVqCU306VnMeta || {},
    alias: supportBizuVqCU306VnMeta?.alias || [],
    redirect: supportBizuVqCU306VnMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/supportBiz.vue").then(m => m.default || m)
  },
  {
    name: teammembersYXLYuFXamZMeta?.name ?? "company-id-teammembers",
    path: teammembersYXLYuFXamZMeta?.path ?? "teammembers",
    meta: teammembersYXLYuFXamZMeta || {},
    alias: teammembersYXLYuFXamZMeta?.alias || [],
    redirect: teammembersYXLYuFXamZMeta?.redirect,
    component: () => import("/vercel/path0/pages/company/[id]/teammembers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mainWBumncU2ZSMeta?.name ?? "main",
    path: mainWBumncU2ZSMeta?.path ?? "/main",
    meta: mainWBumncU2ZSMeta || {},
    alias: mainWBumncU2ZSMeta?.alias || [],
    redirect: mainWBumncU2ZSMeta?.redirect,
    component: () => import("/vercel/path0/pages/main.vue").then(m => m.default || m)
  },
  {
    name: news_45writing4pW0cVBBxcMeta?.name ?? "news-writing",
    path: news_45writing4pW0cVBBxcMeta?.path ?? "/news-writing",
    meta: news_45writing4pW0cVBBxcMeta || {},
    alias: news_45writing4pW0cVBBxcMeta?.alias || [],
    redirect: news_45writing4pW0cVBBxcMeta?.redirect,
    component: () => import("/vercel/path0/pages/news-writing.vue").then(m => m.default || m)
  },
  {
    name: okceo1X60MJYpbiMeta?.name ?? "okceo",
    path: okceo1X60MJYpbiMeta?.path ?? "/okceo",
    meta: okceo1X60MJYpbiMeta || {},
    alias: okceo1X60MJYpbiMeta?.alias || [],
    redirect: okceo1X60MJYpbiMeta?.redirect,
    component: () => import("/vercel/path0/pages/okceo.vue").then(m => m.default || m),
    children: [
  {
    name: dashboarda6tZA4OddUMeta?.name ?? "okceo-dashboard",
    path: dashboarda6tZA4OddUMeta?.path ?? "dashboard",
    meta: dashboarda6tZA4OddUMeta || {},
    alias: dashboarda6tZA4OddUMeta?.alias || [],
    redirect: dashboarda6tZA4OddUMeta?.redirect,
    component: () => import("/vercel/path0/pages/okceo/dashboard.vue").then(m => m.default || m)
  },
  {
    name: settingsbhbroM0c6IMeta?.name ?? "okceo-settings",
    path: settingsbhbroM0c6IMeta?.path ?? "settings",
    meta: settingsbhbroM0c6IMeta || {},
    alias: settingsbhbroM0c6IMeta?.alias || [],
    redirect: settingsbhbroM0c6IMeta?.redirect,
    component: () => import("/vercel/path0/pages/okceo/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: performance_45surveyYuCDnLYJuPMeta?.name ?? "performance-survey",
    path: performance_45surveyYuCDnLYJuPMeta?.path ?? "/performance-survey",
    meta: performance_45surveyYuCDnLYJuPMeta || {},
    alias: performance_45surveyYuCDnLYJuPMeta?.alias || [],
    redirect: performance_45surveyYuCDnLYJuPMeta?.redirect,
    component: () => import("/vercel/path0/pages/performance-survey.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsdvIr0ZzimIMeta?.name ?? "performance-survey-analytics",
    path: analyticsdvIr0ZzimIMeta?.path ?? "analytics",
    meta: analyticsdvIr0ZzimIMeta || {},
    alias: analyticsdvIr0ZzimIMeta?.alias || [],
    redirect: analyticsdvIr0ZzimIMeta?.redirect,
    component: () => import("/vercel/path0/pages/performance-survey/analytics.vue").then(m => m.default || m)
  },
  {
    name: companies7uYirmijdqMeta?.name ?? "performance-survey-companies",
    path: companies7uYirmijdqMeta?.path ?? "companies",
    meta: companies7uYirmijdqMeta || {},
    alias: companies7uYirmijdqMeta?.alias || [],
    redirect: companies7uYirmijdqMeta?.redirect,
    component: () => import("/vercel/path0/pages/performance-survey/companies.vue").then(m => m.default || m)
  },
  {
    name: performancegVB0EIfm2ZMeta?.name ?? "performance-survey-performance",
    path: performancegVB0EIfm2ZMeta?.path ?? "performance",
    meta: performancegVB0EIfm2ZMeta || {},
    alias: performancegVB0EIfm2ZMeta?.alias || [],
    redirect: performancegVB0EIfm2ZMeta?.redirect,
    component: () => import("/vercel/path0/pages/performance-survey/performance.vue").then(m => m.default || m)
  },
  {
    name: programsui9SajmMgcMeta?.name ?? "performance-survey-programs",
    path: programsui9SajmMgcMeta?.path ?? "programs",
    meta: programsui9SajmMgcMeta || {},
    alias: programsui9SajmMgcMeta?.alias || [],
    redirect: programsui9SajmMgcMeta?.redirect,
    component: () => import("/vercel/path0/pages/performance-survey/programs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91type_93bE8ovGuA9dMeta?.name ?? "plugins-type",
    path: _91type_93bE8ovGuA9dMeta?.path ?? "/plugins/:type()",
    meta: _91type_93bE8ovGuA9dMeta || {},
    alias: _91type_93bE8ovGuA9dMeta?.alias || [],
    redirect: _91type_93bE8ovGuA9dMeta?.redirect,
    component: () => import("/vercel/path0/pages/plugins/[type].vue").then(m => m.default || m)
  },
  {
    name: indexXwH4DxFxURMeta?.name ?? "plugins",
    path: indexXwH4DxFxURMeta?.path ?? "/plugins",
    meta: indexXwH4DxFxURMeta || {},
    alias: indexXwH4DxFxURMeta?.alias || [],
    redirect: indexXwH4DxFxURMeta?.redirect,
    component: () => import("/vercel/path0/pages/plugins/index.vue").then(m => m.default || m)
  },
  {
    name: salesAwqpridKucMeta?.name ?? "sales",
    path: salesAwqpridKucMeta?.path ?? "/sales",
    meta: salesAwqpridKucMeta || {},
    alias: salesAwqpridKucMeta?.alias || [],
    redirect: salesAwqpridKucMeta?.redirect,
    component: () => import("/vercel/path0/pages/sales.vue").then(m => m.default || m),
    children: [
  {
    name: chartsfrZAg5zesOMeta?.name ?? "sales-charts",
    path: chartsfrZAg5zesOMeta?.path ?? "charts",
    meta: chartsfrZAg5zesOMeta || {},
    alias: chartsfrZAg5zesOMeta?.alias || [],
    redirect: chartsfrZAg5zesOMeta?.redirect,
    component: () => import("/vercel/path0/pages/sales/charts.vue").then(m => m.default || m)
  },
  {
    name: goalsNZ2A82zN6uMeta?.name ?? "sales-goals",
    path: goalsNZ2A82zN6uMeta?.path ?? "goals",
    meta: goalsNZ2A82zN6uMeta || {},
    alias: goalsNZ2A82zN6uMeta?.alias || [],
    redirect: goalsNZ2A82zN6uMeta?.redirect,
    component: () => import("/vercel/path0/pages/sales/goals.vue").then(m => m.default || m)
  },
  {
    name: invoicesKeS6Oa0OmZMeta?.name ?? "sales-invoices",
    path: invoicesKeS6Oa0OmZMeta?.path ?? "invoices",
    meta: invoicesKeS6Oa0OmZMeta || {},
    alias: invoicesKeS6Oa0OmZMeta?.alias || [],
    redirect: invoicesKeS6Oa0OmZMeta?.redirect,
    component: () => import("/vercel/path0/pages/sales/invoices.vue").then(m => m.default || m)
  }
]
  },
  {
    name: searchBIFC2dE21WMeta?.name ?? undefined,
    path: searchBIFC2dE21WMeta?.path ?? "/search",
    meta: searchBIFC2dE21WMeta || {},
    alias: searchBIFC2dE21WMeta?.alias || [],
    redirect: searchBIFC2dE21WMeta?.redirect,
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: companiesf65Xa76TCwMeta?.name ?? "search-companies",
    path: companiesf65Xa76TCwMeta?.path ?? "companies",
    meta: companiesf65Xa76TCwMeta || {},
    alias: companiesf65Xa76TCwMeta?.alias || [],
    redirect: companiesf65Xa76TCwMeta?.redirect,
    component: () => import("/vercel/path0/pages/search/companies.vue").then(m => m.default || m)
  },
  {
    name: indexDqV3Lxa5AvMeta?.name ?? "search",
    path: indexDqV3Lxa5AvMeta?.path ?? "",
    meta: indexDqV3Lxa5AvMeta || {},
    alias: indexDqV3Lxa5AvMeta?.alias || [],
    redirect: indexDqV3Lxa5AvMeta?.redirect,
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  }
]
  }
]