import axios from 'axios'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.config.errorHandler = error => {
    const route = useRoute()
    const message = error instanceof Error ? error.message : error

    if (process.env.NODE_ENV === 'production') {
      axios({
        url: 'https://hooks.slack.com/services/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',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { text: `${route.fullPath} - ${message}` }
      })
    }
  }
})
