const containsTrackingURL = (pages: string[], pathname: string): boolean =>
  pages.some(page => new RegExp(`^${page}`).test(pathname))
const trackingPluginPages = ['/sales', '/okceo']

export default defineNuxtRouteMiddleware(async to => {
  const config = useRuntimeConfig()
  const { $Parse, $user } = useNuxtApp()
  const href = `${config.public.CLIENT_APP_URL}${to.fullPath}`

  try {
    if (containsTrackingURL(trackingPluginPages, to.path)) {
      $Parse.Cloud.run(
        'RecordUsingPluginToViewLog',
        {
          url: href
        },
        $user.parseToken
      )
    }
    $Parse.Cloud.run('TrackingPage', { href })
  } catch (error) {
    console.error('TrackingPage:', href, error)
  }

  return true
})
