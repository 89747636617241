import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import index_BLNk15bRmh from "/vercel/path0/plugins/theAlert/index.ts";
import copy_69yutnzxvP from "/vercel/path0/plugins/copy.ts";
import errorHandler_x1Y4n5NP4w from "/vercel/path0/plugins/errorHandler.ts";
import user_0y9HlFsUwP from "/vercel/path0/plugins/user.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/plugins/vuetify.ts";
import zillinks_Fh4owqq4ZR from "/vercel/path0/plugins/zillinks.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  index_BLNk15bRmh,
  copy_69yutnzxvP,
  errorHandler_x1Y4n5NP4w,
  user_0y9HlFsUwP,
  vuetify_7h9QAQEssH,
  zillinks_Fh4owqq4ZR
]