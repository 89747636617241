<script setup lang="ts">
import { ref } from 'vue'

defineExpose({
  showAlert
})

const messages = ref<string[]>([])

function showAlert(msg: string) {
  if (messages.value.indexOf(msg) !== -1) return

  // Add message
  messages.value.push(msg)

  // Remove message
  setTimeout(() => {
    hideAlert(msg)
  }, 5000)
}
function hideAlert(msg: string) {
  messages.value.splice(messages.value.indexOf(msg), 1)
}
</script>

<template>
  <div class="the-alert">
    <transition-group
      tag="ul"
      name="the-alert">
      <li
        v-for="msg in messages"
        :key="msg"
        class="msg-box">
        <div class="msg-box-msg">
          <div v-html="msg"></div>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<style scoped lang="scss">
.the-alert {
  $gap: 20px;
  position: fixed;
  bottom: $gap;
  left: $gap;
  right: $gap;
  z-index: 99;
  text-align: center;
  .msg-box {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .msg-box-msg {
      display: inline-flex;
      align-items: center;
      padding: 14px 22px;
      border-radius: 6px;
      background: rgba(#2b3960, 0.8);
      color: white;
      line-height: 1.4;
      .icon {
        color: white;
        margin-right: 10px;
        opacity: 0.5;
      }
    }
  }
}

.the-alert-enter-active {
  animation: fadeInUp 0.6s;
}
.the-alert-leave-active {
  // animation: fadeOutLeft 0.6s;
}
</style>
