import * as echarts from 'echarts'
import _throttle from 'lodash/throttle'

export default defineNuxtPlugin(async () => {
  const Parse = await (async () => {
    if (process.server) {
      // 서버인 경우, process.env를 통해 환경변수에 접근!
      const Parse = (await import('parse/node')).default
      Parse.initialize(
        process.env.NUXT_PUBLIC_APPLICATION_ID as string,
        process.env.NUXT_PUBLIC_JAVASCRIPT_KEY as string
      )
      Parse.serverURL = process.env.NUXT_PUBLIC_PARSE_URL as string
      return Parse
    } else {
      // 클라이언트인 경우, 런타임 환경변수를 통해 접근!
      const Parse = (await import('parse')).default
      const config = useRuntimeConfig()
      Parse.initialize(
        config.public.APPLICATION_ID,
        config.public.JAVASCRIPT_KEY
      )
      Parse.serverURL = config.public.PARSE_URL
      return Parse
    }
  })()

  interface Linker {
    get(url: string): string
    push(url: string): void
  }
  const linker: Linker = {
    get(url: string) {
      return isInnerPath(url) ? url : getHttpUrl(url)
    },
    async push(url: string) {
      const router = useRouter()
      isInnerPath(url)
        ? await router.push(url)
        : process.server
          ? navigateTo(getHttpUrl(url), { external: true })
          : window.location.assign(getHttpUrl(url))
    }
  }

  function getHttpUrl(url: string) {
    const config = useRuntimeConfig()
    return /^https?:\/\//.test(url) // 프로토콜로 구분!
      ? url
      : `${config.public.CLIENT_WWW_URL}${url}`
  }
  function isInnerPath(_url: string) {
    const url = _url.split('?')[0]
    const router = useRouter()
    const myRoutes = router.getRoutes().map(route => route.path)
    return myRoutes.some(route => {
      // console.log('---------------')
      // console.log(route)
      // console.log(url)
      // console.log(new RegExp(`^${route}`).test(`${url}`))
      // return route === '/' ? false : new RegExp(`^${route}`).test(`${url}`)
      const regex = convertPatternToRegex(route)
      return route === '/' ? false : regex.test(url)
    })
  }
  function convertPatternToRegex(pattern: string): RegExp {
    // 경로 파라미터를 정규 표현식으로 변환 (예: '/company/:id()' -> '/company/[^/]+')
    const regexPattern = pattern.replace(/:[^\s/]+/g, '[^/]+')
    return new RegExp(`^${regexPattern}`)
  }

  function chart(
    el: HTMLElement,
    options: echarts.EChartsOption,
    opts: {
      notMerge?: boolean
      replaceMerge?: string | string[]
      lazyUpdate?: boolean
    }
  ) {
    el.classList.add('zchart')
    const chart = echarts.init(el)
    chart.setOption(options, opts)

    // 차트에 라이브러리에서 지정하는 기본 너비가 적용되는 것을 방지!
    if (!document.getElementById('zchart-style')) {
      const styleEl = document.createElement('style')
      styleEl.id = 'zchart-style'
      styleEl.append('.zchart > div { width: auto !important; }')
      document.head.append(styleEl)
    }

    // 1초에 한 번만 동작
    window.addEventListener(
      'resize',
      _throttle(() => {
        chart.setOption(options)
        chart.resize()
      }, 200)
    )
    return chart
  }

  return {
    provide: {
      Parse,
      linker,
      chart
    }
  }
})
