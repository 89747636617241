
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify 스타일 및 아이콘 import
import 'vuetify/styles'
// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'


export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    components,
    directives
  })

  nuxtApp.vueApp.use(vuetify)
})

// import { createVuetify } from 'vuetify'
// import 'vuetify/styles' // Ensure you are using css-loader
// import { aliases, mdi } from 'vuetify/iconsets/mdi'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

// const vuetify = createVuetify({
//   components,
//   directives,
//   icons: {
//     defaultSet: 'mdi',
//     aliases,
//     sets: {
//       mdi
//     }
//   }
// })

// export default vuetify
