import { createApp } from 'vue'
import type { ComponentPublicInstance } from 'vue'
import TheAlert from './theAlert.vue'

let vm: ComponentPublicInstance<typeof TheAlert>
let alert: (msg: string) => void
if (process.client) {
  const theAlert = createApp(TheAlert)
  vm = theAlert.mount(document.createElement('div')) as ComponentPublicInstance<
    typeof TheAlert
  >
  document.body.appendChild(vm.$el)
  alert = (msg: string) => {
    vm.showAlert(msg)
  }
}
export { alert }
export default defineNuxtPlugin(() => ({
  provide: {
    alert: (msg: string) => {
      vm.showAlert(msg)
    }
  }
}))
