import Cookies from 'js-cookie'
import cookieParser from 'cookie'

export default defineNuxtRouteMiddleware(async to => {
  const config = useRuntimeConfig()
  const { $Parse } = useNuxtApp()

  // 라우터 가드를 사용하지 않는 경우!
  if (to.query.noGuard === 'True') return

  // 쿠키로 로그인 사용자 정보 가져오기!
  const { cookie } = useRequestHeaders()
  const token = process.server
    ? cookie && cookieParser.parse(cookie)[config.public.LOGIN_COOKIE]
    : Cookies.get(config.public.LOGIN_COOKIE)
  let me = { id: '' }
  if (token) {
    try {
      // @ts-ignore
      me = await $Parse.User.me(token)
    } catch (error) {
      console.log('비로그인 상태입니다!(User.me)')
    }
    if (me.id) {
      process.client && (await $Parse.User.become(token)) // Save current user to localStorage
    }
  } else {
    // 로그인해서 인증 필수 페이지에 접근한 사용자 중,
    // www.zillinks에서 로그아웃 한 사용자를 위해 쿠키가 없으면 현재 페이지에서도 로그아웃!
    // 단, 쿠키가 없어도 비회원 계정인 경우는 로그아웃 하지 않음!
    const user = $Parse.User.current()
    const authData = user ? user.get('authData') : {}
    // 비회원 계정이 아니면, 로그아웃!
    if (!authData || !authData.anonymous) {
      $Parse.User.logOut()
    }
  }

  return true
})
